<template lang="pug">
  div
    el-card(body-style="padding:0")
      div(slot="header")
        el-alert(
          type="warning"
          title="Rezervasyon oluşturma yetkiniz yok"
          v-if="!can('entries.edit')"
          show-icon
          :closable="false"
        )
        el-form(inline v-if="can('entries.create') || can('entries.edit')")
          el-form-item(label="")
            el-cascader(
              v-model="client"
              :props="{ value: 'id', label: 'name' }"
              placeholder="Acenta, rehber, taksi, hanut vs."
              :options="clients"
              filterable
              clearable
              style="width: 400px"
              :filter-method="filterClients"
              @change="setClient"
              default-first-option
            )
          el-form-item(label="Yaka no")
            el-select(v-model.number="entry.badge" filterable clearable placeholder="Yaka No" style="width: 130px" default-first-option)
              el-option(v-for="i in badges" :key="i" :value="i" :label="i" v-if="i !== 0" :disabled="usedBadges.includes(i)")
          el-form-item(label="Gelen pax")
            el-input(v-model.number="entry.initial_pax" :min="1" type="number" suffix-icon="el-icon-user" placeholder="Gelen pax" style="width: 130px")
          el-form-item(label="Giren pax")
            el-input(v-model.number="entry.pax" :min="1" type="number" suffix-icon="el-icon-user" placeholder="Giren pax" style="width: 130px")
          el-form-item(label="Milliyet")
            el-select(v-model="entry.nationality_id" filterable clearable placeholder="Milliyet" style="width: 180px" default-first-option)
              el-option(v-for="n in nationalities" :key="n.id" :value="n.id" :label="n.name")
          el-form-item(label="")
            el-button(type="success" @click="save" icon="el-icon-check" :loading="saving" :disabled="!saveable") Kaydet
    el-card.mt-5(body-style="padding:0" v-if="can('entries.list')")
      div(slot="header")
        el-form(inline)
          el-form-item(label="Getiren")
            el-select(v-model="filters.type" filterable clearable placeholder="Hepsini göster" default-first-option)
              el-option(value="agency" label="Acenta")
              el-option(value="taxi" label="Taksi")
              el-option(value="dealer" label="Hanut")
              el-option(value="ind" label="Kapı müşterisi")
          el-form-item(label="Tarih")
            el-date-picker(
              v-model="filters.date"
              type="date"
              format="dd.MM.yyyy"
              value-format="yyyy-MM-dd"
              :picker-options="{ firstDayOfWeek: 1 }"
              style="width: 145px"
              @change="filters.period = []"
              clearable
              placeholder="Tarih"
            )
          el-form-item(label="Tarih aralığı")
            el-date-picker(
              v-model="filters.period"
              type="daterange"
              format="dd.MM.yyyy"
              value-format="yyyy-MM-dd"
              :picker-options="$store.state.dateranges"
              range-separator="—"
              start-placeholder="Başlangıç"
              end-placeholder="Bitiş"
              style="width: 300px"
              @change="filters.date = null"
              clearable
            )
          el-form-item
            el-select(v-model="filters.displayCurrency" placeholder="Para birimi" style="width: 90px" @change="fetch(false)")
              el-option(v-for="c in $store.state.currencies" :key="c" :value="c" :label="c")
          el-form-item
            el-button(:loading="fetching" icon="el-icon-tickets" @click="fetch(false)") Getir
            el-button(:loading="downloading" icon="el-icon-download" @click="fetch(true)") Excel
      el-table(
        :data="entries"
        size="small"
        @row-dblclick="edit"
        :height="$store.state.windowHeight-216"
        :show-summary="true"
        :summary-method="totals"
        v-if="entries.length"
      )
        el-table-column(type="index" width="34px")
        el-table-column(prop="branch.name" label="Şube" sortable show-overflow-tooltip width="150px")
        el-table-column(prop="client_type.name" label="Getiren" sortable show-overflow-tooltip width="170px")
          template(v-slot="props")
            el-tag(size="small" effect="dark") {{ props.row.client_type.name }}
            strong(v-if="props.row.company !== null") &nbsp;{{ props.row.company.name }}
        el-table-column(prop="client.name" label="İsim" sortable show-overflow-tooltip width="150px")
          template(v-slot="props")
            span {{ props.row.client.name }}
        el-table-column(prop="nationality.name" label="Milliyet" sortable width="100px")
        el-table-column(prop="badge" label="Yaka no" sortable align="center" width="100px")
        el-table-column(label="Pax" align="center")
          el-table-column(prop="initial_pax" label="Gelen" sortable align="center" class-name="text-danger" width="90px")
          el-table-column(prop="pax" label="Giren" sortable align="center" class-name="text-success" width="90px")
        el-table-column(prop="paid" label="Toplam satış" sortable align="right" width="120px")
          template(v-slot="props"): strong.text-success {{ props.row.paid }} {{ filters.displayCurrency }}
        el-table-column(prop="paxAvg" label="Pax Ort." sortable align="right" width="120px")
          template(v-slot="props"): strong.text-primary {{ props.row.paxAvg }} {{ filters.displayCurrency }}
        el-table-column(prop="driver_payment" label="Şoför ödemesi" sortable width="130px" align="right" class-name="text-danger")
          template(v-slot="props") {{ props.row.driver_payment }} {{ filters.displayCurrency }}
        el-table-column(prop="entered_at" label="Giriş tarihi" sortable align="center" width="130px")
          template(v-slot="props") {{ props.row.entered_at|dayjs('DD.MM.YYYY / HH:mm') }}
        el-table-column(prop="leaved_at" label="Çıkış tarihi" sortable align="center" width="130px")
          template(v-slot="props")
            span(v-if="props.row.leaved_at != null") {{ props.row.leaved_at|dayjs('DD.MM.YYYY / HH:mm') }}
            span.text-danger(v-else) Müşteri içerde
        el-table-column(prop="id" align="center" width="170px")
          template(v-slot="props")
            el-button-group
              el-button.micro(plain @click="edit(props.row); leaveModal = true;" :disabled="!can('entries.edit')")
                span.el-icon-upload2
                span Çıkış yap
              el-button.micro(plain @click="edit(props.row);" :disabled="!can('entries.edit')" icon="el-icon-edit")
              el-button.micro(plain @click="remove(props.row.id)" :disabled="!can('entries.remove')")
                span.el-icon-delete.text-danger
      van-empty(v-if="!entries.length" description="Kayıt bulunamadı")
    el-dialog(
      :visible.sync="leaveModal"
      title="ŞOFÖR ÖDEMESİ"
      width="450px"
      center
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    )
      el-alert(type="warning" title="Şoföre ödeme yapıldıysa tutarı girerek kaydediniz" :closable="false" center show-icon)
      el-input.pt-20.pl-10.pr-10(type="number" v-model="entry.driver_payment")
        template(slot="append")
          el-select(v-model="entry.driver_currency" filterable)
            el-option(v-for="c in $store.state.currencies" :key="c" :value="c" :label="c")
      div(slot="footer")
        el-button(type="primary" @click="leave") Kaydet
        el-button(size="mini" type="text" @click="leaveModal = false") Vazgeç
</template>
<script>
import Type from '@/models/Type'
import Entry from '@/models/Entry'
import collect from 'collect.js'
export default {
  name: 'entries',
  data(){
    return {
      saving: false,
      fetching: false,
      downloading: false,
      filters: {
        view: 'entered',
        branch_id: null,
        company_id: null,
        type: null,
        date: window.dayjs().format('YYYY-MM-DD'),
        period: [],
        displayCurrency: 'USD'
      },
      entry: new Entry({
        branch_id: null,
        company_id: null,
        client_type_id: null,
        client_id: null,
        nationality_id: null,
        badge: null,
        initial_pax: 1,
        pax: 1,
        driver_payment: 0,
        entered_at: window.dayjs().format('YYYY-MM-DD HH:mm:ss'),
        leaved_at: null
      }),
      leaveModal: false,
      entries: [],
      client: null,
      clients: [],
      badges: [...Array(1001).keys()],
      nationalities: [],

    }
  },
  async created(){
    if(this.can('entries.list')) {
      this.nationalities = await Type.where('category', 'nationality').get()
      const { data } = await window.axios.get('clientstree')
      this.clients = data
      this.fetch(false)
    }
  },
  methods: {
    async fetch(excel) {
      if (!excel) {
        this.entries = []
        this.fetching = true
      } else {
        this.downloading = true
      }
      let data = await Entry.include(['company', 'client', 'clientType', 'nationality', 'branch'])
        .where('company_id', this.filters.company_id)
        .where('branch_id', this.$store.state.branch.id)
        .where('entered_at', this.filters.date)
        .where('client_type_id', this.filters.type)
        .whereIn('period', this.filters.period || [])
        .params({
          excel,
          warehouses: [this.$store.state.branch.id],
          displayCurrency: this.filters.displayCurrency
        })
        .get()
      if (!excel) {
        this.entries = data
        this.fetching = false
      } else {
        window.location.href = data[0].url
        this.downloading = false
      }
      this.fetching = false
    },
    create(){
      if (this.can('entries.create')) {
        this.client = null
        this.entry = new Entry({
          company_id: null,
          branch_id: this.$store.state.branch.id,
          client_id: null,
          client_type_id: null,
          nationality_id: null,
          badge: null,
          initial_pax: 1,
          pax: 1,
          driver_payment: 0,
          entered_at: null,
          leaved_at: null
        })
      }
    },
    async edit({ id }) {
      if (this.can('entries.edit')) {
        this.entry = await Entry.find(id)
        let client = [this.entry.client_type_id]
        if (this.entry.company_id != null) {
          client.push(this.entry.company_id)
        }
        if (this.entry.client_id != null) {
          client.push(this.entry.client_id)
        }
        this.client = client
      }
    },
    async save() {
      try {
        this.saving = true
        this.entry.branch_id = this.$store.state.branch.id
        await this.entry.save()
        this.saving = false
        this.leaveModal = false
        this.create()
        this.fetch(false)
      } catch(e) {
        this.saving = false
        this.$message.error(e.message)
      }
    },
    async leave() {
      await window.axios.post(`entries/${this.entry.id}/leave`, this.entry)
      this.saving = false
      this.leaveModal = false
      this.create()
      this.fetch(false)
    },
    setClient(val) {
      if (val.length > 1) {
        this.entry.client_type_id = val[0]
        if (val.length === 3) {
          this.entry.client_id = val[2]
          this.entry.company_id = val[1]
        } else if(val.length === 2) {
          this.entry.client_id = val[1]
          this.entry.company_id = null
        }
      } else {
        this.entry.client_type_id = null
      }
    },
    filterClients(node, keyword) {
      return node.text.toLowerCase().includes(keyword)
    },
    totals({ columns, data }) {
      data = collect(data)
      let totalSale = data.sum('paid')
      let totalPax = data.sum('pax')
      let paxAvg = 0
      if(totalSale > 0 && totalPax){
        paxAvg = totalSale/totalPax
      }
      let driverPayment = data.sum('driver_payment')
      return [
        '',
        '',
        '',
        '',
        '',
        'TOPLAMLAR',
        data.sum('initial_pax') + ' pax',
        totalPax.toFixed(2) + ' pax',
        totalSale.toFixed(2) + ' ' + this.filters.displayCurrency,
        paxAvg.toFixed(2) + ' ' + this.filters.displayCurrency,
        driverPayment.toFixed(2) + ' ' + this.filters.displayCurrency,
        ''
      ]
    },
    async remove(id) {
      if(this.can('entries.remove')) {
        await this.$confirm('Kayıt silinmesine onay veriniz', 'Uyarı', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Vazgeç',
          type: 'warning',
          center: true
        })
        try{
          let { data } = await window.axios.delete('/entries/' + id)
          this.fetch(false)
          if(data.deleted) {
            this.$message({
              type: 'success',
              message: 'Kayıt silinmiştir',
              center: true,
              closable: true
            })
          }
        } catch(e) {
          this.$message({
            type: 'error',
            message: 'Rezervasyon üzerinde satış olduğundan dolayı kayıt silinemez',
            center: true,
            closable: false
          })
        }
      }
    }
  },
  computed: {
    saveable() {
      return (
        this.entry.client_id
        && this.entry.badge
        && this.entry.initial_pax > 0
        && this.entry.pax > 0
        && this.entry.nationality_id
      )
    },
    usedBadges: function() {
      return this.entries.map(e => e.badge)
    }
  }
}
</script>
